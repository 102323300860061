import { useGoogleLogin } from '@fluentsolar/shared/hooks/useGoogleLogin';
import { useLogin } from '@fluentsolar/shared/hooks/useLogin';
import { GoogleLoginButton } from '@fluentsolar/shared-web/feature-GoogleLoginButton';
import { HeadFont } from '@fluentsolar/shared-web/feature-HeadFont';
import { useApolloState } from '@fluentsolar/shared-web/localHook';
import { Textography } from '@fluentsolar/shared-web/Textography';
import { ASYNC_STATUS } from '@fluentsolar/types';
import { useRouter } from 'next/router';
import * as React from 'react';
import * as ga from '@fluentsolar/ga';

import { FluentLogo } from '@fluentsolar/shared-web/feature-FluentLogo/FluentLogo';
import axios from 'axios';

export const LoginPage = (props: {
  onSuccessRoute: string;
  className?: string;
  backgroundImage?: string;
  app?: string;
  style?: React.CSSProperties
}) => {
  const [message, setMessage] = React.useState('');
  const { setAllState } = useApolloState();
  const router = useRouter();

  const { setPassword, setEmail, onLogin, ...loginInfo } = useLogin();

  React.useEffect(() => {
    if (loginInfo.message !== '' && loginInfo.status !== ASYNC_STATUS.IDLE) {
      setMessage(loginInfo.message);
    }
  }, [loginInfo.status, loginInfo.message]);

  React.useEffect(() => {
    const onSuccess = async () => {
      setAllState({ ...loginInfo?.userInfo });

      console.log('pants');
      ga.setUser(loginInfo?.userInfo?.id?.toString());
      if (props.app) {
        await axios.post('/api/appevent', {
          app: props.app,
          type: 'LOGIN',
          email: loginInfo.userInfo?.email,
        });
      }
      router.push(props.onSuccessRoute);
    };

    if (loginInfo.status === ASYNC_STATUS.SUCCESS && loginInfo?.userInfo) {
      onSuccess();
    }
  }, [loginInfo?.status, loginInfo?.userInfo, props.onSuccessRoute, props.app]);

  const { onGoogleSignIn, onGoogleFail, googleLoginInfo, ...googleInfo } =
    useGoogleLogin();

  React.useEffect(() => {
    if (googleInfo.status !== ASYNC_STATUS.IDLE) {
      setMessage(googleInfo.message);
    }

    if (googleInfo.status === ASYNC_STATUS.SUCCESS) {
      console.log('am i running?');
      setAllState({ ...googleInfo?.userInfo });
      ga.setUser(googleInfo?.userInfo?.id?.toString());
      router.push(props.onSuccessRoute);
    }
  }, [googleInfo]);

  React.useEffect(() => {
    if (router?.query?.reason) {
      const { reason } = router?.query;
      switch (reason) {
        case 'NO_TOKEN': {
          setMessage('You must log in again.');
          break;
        }
        case 'PASSWORD_CHANGED': {
          setMessage('Log in with new password.');
          break;
        }
        default:
          break;
      }
    }
  }, []);

  return (
    <div style={props.style}>
      <HeadFont />
      <div
        className={`w-full min-h-screen bg-center bg-cover ${props.className}`}
        style={{
          backgroundImage:
            props.backgroundImage ||
            'url("https://images.unsplash.com/photo-1463173904305-ba479d2123b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1426&q=80")',
        }}
      >
        <div className="h-12 sm:h-56" />

        <DivCenter>
          <FluentLogo />
        </DivCenter>
        <div className="h-20" />

        <DivCenter>
          <div className="flex flex-col">
            <div>
              <Textography className="text-white">{message}</Textography>
            </div>
            <div>
              <Textography className="text-white">
                {googleLoginInfo?.error &&
                  JSON.stringify(googleLoginInfo?.error)}
              </Textography>
            </div>
          </div>
        </DivCenter>
        <div className="h-6" />
        <div className="flex flex-col w-full px-6 m-auto sm:w-4/6 lg:w-1/2 xl:w-1/4">
          <div className="flex flex-col w-full">
            <label className="flex flex-col">
              Email
              <input
                placeholder="Email"
                className="h-12 px-4 text-gray-900 bg-white rounded shadow-file-button focus:outline-none focus:ring-2 focus:ring-help"
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </label>

            <label className="flex flex-col mt-4 text-white">
              Password
              <input
                placeholder="Password"
                className="h-12 px-4 text-gray-900 bg-white rounded shadow-file-button focus:outline-none focus:ring-2 focus:ring-help"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    onLogin();
                  }
                }}
              />
            </label>
          </div>

          <div className="h-6" />

          <DivCenter flexDirection="col" width="full">
            <button
              className="w-full h-12 text-white rounded bg-gradient-to-b from-c-approved-100 to-c-approved-200 shadow-file-button hover:shadow-button-hover"
              onClick={onLogin}
            >
              Login
            </button>
            <div className="h-2" />

            <GoogleLoginButton
              googleLogo="/img/google_logo.png"
              onGoogleSignIn={onGoogleSignIn}
              onFail={onGoogleFail}
              text="Login with Google"
            />
          </DivCenter>
          <div className="h-12" />
          <div style={{ textAlign: 'center', padding: 10 }}>
            {process.env.VERCEL_GIT_COMMIT_SHA?.slice(0, 6)}
          </div>

          <div className="h-12" />
          <DivCenter flexDirection="col" width="full">
            <div className="text-xs italic text-white">
              No user will: Use the tools, software, or content provided by
              Fluent in excess of the access and/or license rights granted to
              such user Allow other individuals to use the user’s username,
              password, or other credentials to access or use Fluent’s tools,
              software, or content Bypass or attempt to bypass any measures
              taken by Fluent to prevent or restrict access to any tools,
              software, or content
            </div>
            <div className="h-6" />
            <div className="text-xs italic text-white ">
              You acknowledge that unauthorized use or access of Fluent’s
              database, software, content, and/or systems could lead to criminal
              liability
            </div>
            <div className="h-6" />
            <div className="text-xs italic text-white ">
              FLUENT RESERVES THE RIGHT TO TERMINATE A USER’S ACCESS AND USE OF
              FLUENT’S TOOLS, SOFTWARE, OR CONTENT, REMOVE AND DISCARD ANY
              CONTENT PROVIDED BY THE USER, AND SUSPEND A USER’S ACCESS OR USE
              INDEFINITELY OR FOR AN AMOUNT OF TIME DECIDED BY FLUENT AT ITS
              SOLE DISCRETION FOR ANY REASON INCLUDING, WITHOUT LIMITATION, A
              BREACH OF THE TERMS AND CONDITIONS HEREOF
            </div>
            <div className="h-6" />
            <div className="text-xs italic text-white ">
              {process.env.VERCEL_GITHUB_COMMIT_SHA}
            </div>
          </DivCenter>
        </div>
      </div>
    </div>
  );
};
interface DivCenterProps {
  flexDirection?: 'row' | 'row-reverse' | 'col' | 'col-reverse';
  children?: React.ReactNode;
  width?: 'full' | '';
}
export const DivCenter: React.FC<DivCenterProps> = (props: DivCenterProps) => {
  return (
    <div
      className={`flex justify-center items-center flex-${props.flexDirection} w-${props.width}`}
    >
      {props.children}
    </div>
  );
};
