import { gql, useMutation } from '@apollo/client';
import { ASYNC_STATUS, login, UserInfo } from '@fluentsolar/types';
import * as React from 'react';

export const MUTATION_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      auth
      message
      token
      user {
        id
        email
        name
        role
        team
        profilePic
        salesRepInfo {
          id
        }
        setterInfo {
          id
        }
        officeName
      }
    }
  }
`;

export function useLogin() {
  const [loginCredentials, setLoginCredentials] = React.useState({
    email: '',
    password: '',
  });
  const [userInfo, setUserInfo] = React.useState<UserInfo>(null);

  const [loginFn, loginInfo] = useMutation<login>(MUTATION_LOGIN);
  const [message, setMessage] = React.useState('');
  const [status, setStatus] = React.useState<ASYNC_STATUS>(ASYNC_STATUS.IDLE);

  function setEmail(text: string) {
    setLoginCredentials({ ...loginCredentials, email: text });
  }

  function setPassword(text: string) {
    setLoginCredentials({ ...loginCredentials, password: text });
  }

  async function onLogin() {
    setStatus(ASYNC_STATUS.PENDING);
    setMessage('Logging You In...');
    try {
      const result = await loginFn({
        variables: {
          email: loginCredentials.email,
          password: loginCredentials.password,
        },
      });
      console.log('result', result);
      if (result?.data?.login?.auth && result?.data?.login?.token) {
        setUserInfo(result?.data?.login?.user);

        setMessage('Authentication Successful...');
        setStatus(ASYNC_STATUS.SUCCESS);
      } else {
        setStatus(ASYNC_STATUS.ERROR);
        setMessage(result?.data?.login?.message);
      }
    } catch (e) {
      setStatus(ASYNC_STATUS.ERROR);
      setMessage(JSON.stringify(e));
      throw new Error(e);
    }
  }

  return { setEmail, setPassword, message, status, onLogin, userInfo, loginFn };
}
