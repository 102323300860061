/// <reference types="node" />

// log the pageview with their URL

export const pageview = (url) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

// log specific events happening.
export const event = ({ action, params }) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    window.gtag('event', action, params);
  }
};

export const setUser = (userId: string) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      user_id: userId,
    });
  }
};
